<template>
  <div class="comapnyForm pt-4">
    <div class="settingGreyCard">
      <div class="company">
        <div class="buttonRows pb-4">
          <div class="titleText">
            <span> 회사 정보 </span>
          </div>
          <div class="companyButtonArea">
            <v-btn
              color="#2c98f0"
              @click="saveCompanyForm"
              width="80"
              height="30"
              :disabled="$store.state.auth_grade < 5 || $store.state.isExpired"
            >
              <span class="whiteText">저장</span>
            </v-btn>
          </div>
        </div>
        <div class="companyField">
          <v-text-field
            dense
            label="회사명"
            v-model="company.name"
            type="text"
            :readonly="$store.state.auth_grade < 5"
          />
          <v-text-field
            dense
            label="대표자명"
            v-model="company.officer"
            type="text"
            :readonly="$store.state.auth_grade < 5"
          />
          <v-text-field
            dense
            label="사업자번호"
            v-model="company.business_num"
            type="text"
            :readonly="$store.state.auth_grade < 5"
            @input="(e) => (company.business_num = this.getBusinessNumMask(e))"
            @keyup="
              $event.target.value = $event.target.value.replace(/[^-0-9]/g, '')
            "
          />

          <v-text-field
            dense
            label="연락 담당자"
            @input="company.liaison = $event"
            :value="$store.state.company.liaison"
            type="text"
            :readonly="$store.state.auth_grade < 5"
          ></v-text-field>

          <v-text-field
            dense
            label="전화번호"
            v-model="$store.state.viewTel"
            type="text"
            @input="(e) => ($store.state.viewTel = this.getPhoneMask(e))"
            @keyup="
              $event.target.value = $event.target.value.replace(/[^-0-9]/g, '')
            "
            maxlength="13"
            :readonly="$store.state.auth_grade < 5"
          ></v-text-field>

          <v-text-field
            dense
            label="주소"
            @input="company.address = $event"
            :value="$store.state.company.address"
            type="text"
            :readonly="$store.state.auth_grade < 5"
          ></v-text-field>

          <v-text-field
            dense
            label="팩스번호"
            @input="company.fax = $event"
            :value="$store.state.company.fax"
            type="text"
            :readonly="$store.state.auth_grade < 5"
          />
        </div>
      </div>
    </div>
    <div class="settingGreyCard">
      <div class="pb-8 d-flex">
        <div class="titleText">로고 변경</div>
        <div class="companyButtonArea">
          <v-btn
            color="#2c98f0"
            @click="saveCompanyForm"
            width="80"
            height="30"
            :disabled="$store.state.auth_grade < 5 || $store.state.isExpired"
          >
            <span class="whiteText">저장</span>
          </v-btn>
        </div>
      </div>
      <div class="d-flex">
        <div class="mr-4">
          <template v-if="preview">
            <v-img
              style="
                float: left;

                border: thin solid rgba(0, 0, 0, 1);
              "
              width="160"
              height="100"
              max-height="100"
              max-width="160"
              contain
              :src="preview"
            />
          </template>

          <template v-else>
            <v-img
              style="
                border: thin solid rgba(0, 0, 0, 1);

                object-fit: cover;
              "
              max-height="100"
              max-width="160"
              src="@/assets/image/png/no-image.png"
            />
          </template>
          <div>
            <span class="labelText text-center d-inline-flex"
              >이미지에 최적화된 크기는 160x100 입니다.</span
            >
          </div>
        </div>

        <div class="" style="width: 100%">
          <v-select
            v-model="company.logoType"
            :items="logoTypeList"
            class="pb-0 mb-0"
            dense
            item-text="label"
            return-object
            label="로고 타입 선택"
            @change="company.logoType = $event.value"
            :disabled="$store.state.auth_grade < 5"
          />
          <v-file-input
            v-model="saveLogoImg"
            accept="image/png, image/jpeg"
            prepend-icon="mdi-camera"
            @change="changeLogo()"
            label="로고 이미지"
            class="pt-0 mt-0 mb-10"
            :disabled="$store.state.auth_grade < 5"
          />
        </div>
      </div>
      <!-- <div class="companyButtonArea">
          <v-btn
            color="#2c98f0"
            @click="saveCompanyForm"
            width="50"
            height="25"
            :disabled="$store.state.auth_grade < 5 || $store.state.isExpired"
          >
            <span class="whiteText">저장</span>
          </v-btn>
        </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { postfile, getfileOneById, downloadFile, deletefile } from "@/api/file";
export default {
  computed: {
    ...mapGetters({
      company: "getEditCompany",
    }),
  },
  data() {
    return {
      saveLogoImg: null,
      preview: "",
      logoTypeList: [
        {
          value: 1,
          label: "로고 이미지 / 로고 텍스트",
        },
        {
          value: 2,
          label: "로고 이미지",
        },
        {
          value: 3,
          label: "로고 텍스트",
        },
      ],
    };
  },
  methods: {
    changeLogo() {
      if (this.saveLogoImg) {
        const fileData = (data) => {
          this.preview = data;
        };
        const reader = new FileReader();

        reader.readAsDataURL(this.saveLogoImg);
        reader.addEventListener(
          "load",
          function () {
            fileData(reader.result);
          },
          false,
        );
      } else {
        this.preview = "";
        this.getLogoImg();
      }
    },
    async getLogoImg() {
      if (this.logoImg._id) {
        try {
          const result = await downloadFile(
            this.$store.state.auth_company,
            this.logoImg._id,
          );

          if (result.status !== 200) throw `error: ${result.status}`;
          const downFile = new Blob([result.data]);

          this.preview = window.URL.createObjectURL(downFile);
        } catch (e) {
          console.log(e);
        }
      }
    },
    async saveCompanyForm() {
      if (!this.$store.state.viewBusinessNum) {
        this.snackbar_text = `사업자 번호를 입력해주세요.`;
        this.snackbar = true;
        return;
      }
      if (!this.company.name) {
        this.snackbar_text = `회사 명을 입력해주세요.`;
        this.snackbar = true;
        return false;
      }
      this.company.business_num = this.$store.state.viewBusinessNum;
      this.company.tel = this.$store.state.viewTel;
      this.company.id = this.$store.state.auth_company;

      await this.postCompany();
      if (this.saveLogoImg) this.saveLogo();

      this.snackbar_text = `Save Complete : ${this.company.id}`;
      this.snackbar = true;

      await this.$store.dispatch("GET_COMPANY");
    },
    async saveLogo() {
      if (this.logoImg) this.logoDelete();
      try {
        const result = await postfile(this.saveFormData(this.saveLogoImg));

        if (result.status !== 200) throw `error: ${result.status}`;

        this.saveLogoImg = null;
        await this.setPrivew();
      } catch (e) {
        console.log(e);
      }
    },

    async logoDelete() {
      try {
        const result = await deletefile(this.logoImg._id);

        if (result.status !== 200) throw `error: ${result.status}`;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style></style>
