<template>
  <v-container fluid class="listContainer pt-0">
    <div class="settingGreyCard d-flex ml-0 mr-0">
      <div class="d-flex ml-auto">
        <template v-if="listType === 'client'">
          <span class="flexCardItem">
            <v-select
              v-model="clientStatus"
              :items="statusType"
              item-text="label"
              label="상태"
              dense
              hide-details
              style="width: 150px"
            ></v-select>
          </span>
          <span class="flexCardItem">
            <v-select
              v-model="clientFilter"
              :items="$store.getters.clientTypeWithAll"
              item-text="label"
              label="구분"
              dense
              hide-details
              style="width: 150px"
            ></v-select>
          </span>
          <span class="flexCardItem">
            <v-text-field
              v-model="billClientSearch"
              append-icon="mdi-magnify"
              label="계산처 발행처 검색"
              dense
              hide-details
              clearable
              style="width: 200px"
            ></v-text-field>
          </span>
          <span class="flexCardItem">
            <v-text-field
              v-model="clientSearch"
              append-icon="mdi-magnify"
              label="거래처 검색"
              dense
              hide-details
              clearable
              style="width: 200px"
            ></v-text-field>
          </span>
        </template>

        <template v-if="listType === 'item'">
          <span class="flexCardItem">
            <v-select
              v-model="itemStatus"
              :items="statusType"
              item-text="label"
              label="상태"
              dense
              hide-details
              style="width: 150px"
            ></v-select>
          </span>

          <span class="flexCardItem">
            <v-text-field
              v-model="productionSearch"
              append-icon="mdi-magnify"
              label="생산처 검색"
              dense
              hide-details
              clearable
              style="width: 200px"
            ></v-text-field>
          </span>
          <span class="flexCardItem">
            <v-text-field
              v-model="itemSearch"
              append-icon="mdi-magnify"
              label="아이템 검색"
              dense
              hide-details
              clearable
              style="width: 200px"
            ></v-text-field>
          </span>
        </template>

        <template v-if="listType === 'ctype'">
          <span class="flexCardItem">
            <v-select
              v-model="ctypeStatus"
              :items="statusType"
              item-text="label"
              label="상태"
              dense
              hide-details
              style="width: 150px"
            ></v-select>
          </span>

          <span class="flexCardItem">
            <v-text-field
              v-model="ctypeSearch"
              append-icon="mdi-magnify"
              label="복종 검색"
              dense
              hide-details
              clearable
              style="width: 200px"
            ></v-text-field>
          </span>
        </template>
      </div>
    </div>
    <div class="buttonRows">
      <div class="leftTab">
        <v-tabs
          background-color="grey lighten-3"
          slider-color="grey lighten-2"
          active-class="activeTab"
          @change="listChange(listTypes[$event].value)"
        >
          <v-tab v-for="item in listTypes" :key="item.value">
            {{ item.label }}
          </v-tab>
        </v-tabs>
        <span class="totalCalculateText">
          <template v-if="viewType === 'list'">
            전체 : {{ listLength | makeComma }} 건 선택 :
            {{ selectedLength | makeComma }} 건
          </template>
          <template v-if="viewType === 'upload'">
            총 업로드 건수 :&nbsp;
            <span class="text-bold">
              {{ `${uploadLength}건` }}
            </span>
          </template>
        </span>
      </div>
      <div class="rightTab pr-2 pb-1">
        <template v-if="viewType === 'upload'">
          <v-btn
            class="settingButton"
            @click="viewChange()"
            samll
            color="grey"
            outlined
            dark
          >
            <span class="buttonText black--text">
              <v-icon> mdi-view-list </v-icon>
              목록 전환</span
            >
          </v-btn>
          <v-btn
            class="settingButton"
            @click="excelUploadDialogOpen()"
            samll
            color="grey"
            outlined
            dark
          >
            <span class="buttonText green--text">
              <v-icon> mdi-file-excel-outline </v-icon>
              엑셀 업로드</span
            >
          </v-btn>
          <v-btn
            class="settingButton"
            samll
            @click="uploadSave('client')"
            color="grey"
            outlined
            dark
          >
            <span class="buttonText blue--text">
              <v-icon> mdi-check </v-icon>
              저장</span
            >
          </v-btn>
        </template>
        <template v-if="viewType === 'list'">
          <span>
            <v-btn
              class="settingButton"
              @click="viewChange"
              samll
              color="grey"
              outlined
              dark
            >
              <span class="buttonText green--text">
                <v-icon> mdi-file-excel-outline </v-icon>
                엑셀 업로드</span
              >
            </v-btn>
          </span>
          <span class="">
            <v-btn
              class="settingButton"
              @click="downloadExcel"
              samll
              color="grey"
              outlined
              dark
            >
              <span class="buttonText green--text">
                <v-icon> mdi-microsoft-excel </v-icon>
                엑셀 다운로드</span
              >
            </v-btn>
          </span>
          <span class="">
            <v-btn
              class="settingButton"
              samll
              @click="removeCol"
              color="grey"
              outlined
              dark
            >
              <span class="buttonText red--text">
                <v-icon> mdi-close </v-icon>
                삭제</span
              >
            </v-btn>
          </span>
          <div class="">
            <v-btn
              class="settingButton"
              samll
              @click="newAddItem"
              color="grey"
              outlined
              dark
            >
              <span class="buttonText blue--text">
                <v-icon> mdi-plus </v-icon>
                신규 등록</span
              >
            </v-btn>
          </div>
        </template>
      </div>
    </div>

    <template v-if="viewType === 'list'">
      <!-- 거래처 -->
      <template v-if="listType === 'client'">
        <article class="flexTable">
          <div
            class="tableHaeder"
            style="width: 100%; background-color: rgb(245, 245, 245) !important"
          >
            <div class="settingHeader" style="width: 100%">
              <div
                class="text-center settingCell"
                v-for="col in clientHeaders"
                :key="col.value"
                :style="{ width: col.width }"
              >
                <span>{{ col.text }}</span>
                <span v-if="col.value === 'check'">
                  <div class="">
                    <input
                      class="v-checkbox"
                      type="checkbox"
                      :checked="allChecked.client"
                      @click="allCheckItem(filterClientList, 'client')"
                    />
                  </div>
                </span>
              </div>
            </div>
          </div>
          <overlay-scrollbars
            :options="{
              overflowBehavior: {
                x: 'hidden',
              },
            }"
          >
            <div class="settingTableBody">
              <template v-if="filterClientList.length">
                <div
                  v-for="(item, index) in filterClientList"
                  :key="`${item._id}_${index}`"
                  class="settingRowCell text-center"
                  style="width: 100%"
                  @click.stop="
                    item.status !== '99' ? openEditClientForm(item, $event) : ''
                  "
                >
                  <div
                    class="settingCell"
                    v-for="(col, i) in clientHeaders"
                    :key="i"
                    :style="{ width: col.width }"
                  >
                    <template v-if="col.value === 'check'">
                      <span>
                        <input
                          class="v-checkbox"
                          type="checkbox"
                          :disabled="item.status === '99'"
                          :checked="item.checked"
                          @click.stop="
                            checkItem(item, filterClientList, 'client')
                          "
                        />
                      </span>
                    </template>
                    <template v-if="col.value === 'company_type'">
                      <span>
                        {{
                          item.company_type
                            ? $store.state.client_type[item.company_type - 1]
                                .label
                            : ""
                        }}
                      </span>
                    </template>
                    <template v-else-if="col.value === 'bill_client'">
                      <span>
                        {{ item.bill_client ? item.bill_client.name : "" }}
                      </span>
                    </template>
                    <template v-else-if="col.value === 'name'">
                      <span>
                        {{
                          item.status !== "99"
                            ? item.name
                              ? item.name
                              : ""
                            : `${item.name} (삭제됨)`
                        }}
                      </span>
                    </template>
                    <template v-else-if="col.value === 'business_num'">
                      <span>
                        {{
                          item.business_num
                            ? getBusinessNumMask(item.business_num)
                            : ""
                        }}
                      </span>
                    </template>
                    <template v-else-if="col.value === 'edit'">
                      <span>
                        <v-icon
                          :disabled="item.status === '99'"
                          @click="openEditClientForm(item)"
                        >
                          mdi-pencil
                        </v-icon>
                      </span>
                    </template>
                    <template v-else-if="col.value === 'tel'">
                      <span>
                        {{ item.tel ? getPhoneMask(item.tel) : "" }}
                      </span>
                    </template>
                    <template v-else-if="col.value === 'status'">
                      <span>
                        {{ item.status ? OrderStatus[item.status] : "" }}
                      </span>
                    </template>
                    <template v-else>
                      <span>
                        {{ item[col.value] }}
                      </span>
                    </template>
                  </div>
                </div>
              </template>
              <template v-else>
                <div
                  class="
                    settingRowCell
                    text-center
                    d-flex
                    justify-center
                    align-center
                  "
                  style="width: 100%; height: 300px"
                >
                  <span class="grey--text"> 목록이 존재하지 않습니다. </span>
                </div>
              </template>
            </div>
          </overlay-scrollbars>
        </article>
      </template>
      <!-- 거래처 end -->

      <!-- 아이템 -->
      <template v-if="listType === 'item'">
        <article class="flexTable">
          <div
            class="tableHaeder"
            style="width: 100%; background-color: rgb(245, 245, 245) !important"
          >
            <div class="settingHeader" style="width: 100%">
              <div
                class="text-center settingCell"
                v-for="col in itemHeaders"
                :key="col.value"
                :style="{ width: col.width }"
              >
                <span>{{ col.text }}</span>
                <span v-if="col.value === 'check'">
                  <div class="">
                    <input
                      class="v-checkbox"
                      type="checkbox"
                      :checked="allChecked.item"
                      @click="allCheckItem(filterItemList, 'item')"
                    />
                  </div>
                </span>
              </div>
            </div>
          </div>
          <overlay-scrollbars
            :options="{
              overflowBehavior: {
                x: 'hidden',
              },
            }"
          >
            <div class="settingTableBody">
              <template v-if="filterItemList.length">
                <div
                  v-for="(item, index) in filterItemList"
                  :key="`${item._id}_${index}`"
                  class="settingRowCell text-center"
                  style="width: 100%"
                  @click.stop="openEditItemForm(item, $event)"
                >
                  <div
                    class="settingCell"
                    v-for="(col, i) in itemHeaders"
                    :key="i"
                    :style="{ width: col.width }"
                  >
                    <template v-if="col.value === 'check'">
                      <span>
                        <input
                          class="v-checkbox"
                          type="checkbox"
                          :disabled="item.status === '99'"
                          :checked="item.checked"
                          @click.stop="checkItem(item, filterItemList, 'item')"
                        />
                      </span>
                    </template>
                    <template v-else-if="col.value === 'name'">
                      <span>
                        {{
                          item.status !== "99"
                            ? item.name
                              ? item.name
                              : ""
                            : `${item.name} (삭제됨)`
                        }}
                      </span>
                    </template>
                    <template v-else-if="col.value === 'unit_price'">
                      <span>
                        {{
                          item.unit_price
                            ? `${item.currency_sign}${item.unit_price}`
                            : "" | makeComma
                        }}
                      </span>
                    </template>
                    <template v-else-if="col.value === 'production'">
                      <span>
                        {{
                          item.production
                            ? `${item.production.name}`
                            : "" | makeComma
                        }}
                      </span>
                    </template>
                    <template v-else-if="col.value === 'status'">
                      <span>
                        {{ item.status ? OrderStatus[item.status] : "" }}
                      </span>
                    </template>
                    <template v-else-if="col.value === 'edit'">
                      <span>
                        <v-icon
                          :disabled="item.status === '99'"
                          @click="openEditItemForm(item)"
                        >
                          mdi-pencil
                        </v-icon>
                      </span>
                    </template>
                    <template v-else>
                      <span>
                        {{ item[col.value] }}
                      </span>
                    </template>
                  </div>
                </div>
              </template>
              <template v-else>
                <div
                  class="
                    settingRowCell
                    text-center
                    d-flex
                    justify-center
                    align-center
                  "
                  style="width: 100%; height: 300px"
                >
                  <span class="grey--text"> 목록이 존재하지 않습니다. </span>
                </div>
              </template>
            </div>
          </overlay-scrollbars>
        </article>
      </template>
      <!-- item end -->

      <!-- 복종 -->
      <template v-if="listType === 'ctype'">
        <article class="flexTable">
          <div
            class="tableHaeder"
            style="width: 100%; background-color: rgb(245, 245, 245) !important"
          >
            <div class="settingHeader" style="width: 100%">
              <div
                class="text-center settingCell"
                v-for="col in ctypeHeaders"
                :key="col.value"
                :style="{ width: col.width }"
              >
                <span v-if="col.value === 'check'">
                  <div class="">
                    <input
                      class="v-checkbox"
                      type="checkbox"
                      :checked="allChecked.ctype"
                      @click="allCheckItem(filterCtypeList, 'ctype')"
                    />
                  </div>
                </span>

                <span v-else>{{ col.text }}</span>
              </div>
            </div>
          </div>
          <overlay-scrollbars
            :options="{
              overflowBehavior: {
                x: 'hidden',
              },
            }"
          >
            <div class="settingTableBody">
              <template v-if="filterCtypeList.length">
                <div
                  v-for="(item, index) in filterCtypeList"
                  :key="`${item._id}_${index}`"
                  class="settingRowCell text-center"
                  style="width: 100%"
                  @click.stop="openEditCtypeForm(item, $event)"
                >
                  <div
                    class="settingCell"
                    v-for="(col, i) in ctypeHeaders"
                    :key="i"
                    :style="{ width: col.width }"
                  >
                    <template v-if="col.value === 'check'">
                      <span>
                        <input
                          class="v-checkbox"
                          type="checkbox"
                          :disabled="item.status === '99'"
                          :checked="item.checked"
                          @click.stop="
                            checkItem(item, filterCtypeList, 'ctype')
                          "
                        />
                      </span>
                    </template>
                    <template v-else-if="col.value === 'status'">
                      <span>
                        {{ item.status ? OrderStatus[item.status] : "" }}
                      </span>
                    </template>
                    <template v-else-if="col.value === 'name'">
                      <span>
                        {{
                          item.status !== "99"
                            ? item.name
                              ? item.name
                              : ""
                            : `${item.name} (삭제됨)`
                        }}
                      </span>
                    </template>
                    <template v-else-if="col.value === 'edit'">
                      <span>
                        <v-icon
                          :disabled="item.status === '99'"
                          @click="openEditItemForm(item)"
                        >
                          mdi-pencil
                        </v-icon>
                      </span>
                    </template>
                    <template v-else>
                      <span>
                        {{ item[col.value] }}
                      </span>
                    </template>
                  </div>
                </div>
              </template>
              <template v-else>
                <div
                  class="
                    settingRowCell
                    text-center
                    d-flex
                    justify-center
                    align-center
                  "
                  style="width: 100%; height: 300px"
                >
                  <span class="grey--text"> 목록이 존재하지 않습니다. </span>
                </div>
              </template>
            </div>
          </overlay-scrollbars>
        </article>
      </template>
      <!-- 복종 end -->
    </template>
    <!-- 업로드 리스트 -->

    <template v-if="viewType === 'upload'">
      <template v-if="listType === 'client'">
        <article class="flexTable">
          <div
            class="tableHaeder"
            style="width: 100%; background-color: rgb(245, 245, 245) !important"
          >
            <div class="settingHeader" style="width: 100%">
              <div
                class="text-center settingCell"
                v-for="col in excelClientHeaders"
                :key="col.value"
                :style="{ width: col.width }"
              >
                <span>{{ col.text }}</span>
                <span v-if="col.value === 'edit'">
                  <div class="">
                    <v-icon small @click="openNewClient()"> mdi-plus </v-icon>
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div class="settingTableBody">
            <template v-if="excelFormList.length">
              <div
                v-for="(item, index) in excelFormList"
                :key="`${item._id}_${index}`"
                class="settingRowCell text-center"
                style="width: 100%"
              >
                <div
                  class="settingCell"
                  v-for="(col, i) in excelClientHeaders"
                  :key="i"
                  :style="{ width: col.width }"
                >
                  <template v-if="col.value === 'business_num'">
                    <span>
                      {{
                        item.business_num
                          ? getBusinessNumMask(item.business_num)
                          : ""
                      }}
                    </span>
                  </template>
                  <template v-else-if="col.value === 'edit'">
                    <span>
                      <v-icon small @click="openEditClientForm(item)">
                        mdi-pencil
                      </v-icon>
                    </span>
                  </template>
                  <template v-else-if="col.value === 'tel'">
                    <span>
                      {{ item.tel ? getPhoneMask(item.tel) : "" }}
                    </span>
                  </template>
                  <template v-else-if="col.value === 'fax'">
                    <span>
                      {{ item.fax ? getPhoneMask(item.fax) : "" }}
                    </span>
                  </template>

                  <template v-else>
                    <span>
                      {{ item[col.value] }}
                    </span>
                  </template>
                </div>
              </div>
            </template>
            <template v-else>
              <div
                class="
                  settingRowCell
                  text-center
                  d-flex
                  justify-center
                  align-center
                "
                style="width: 100%; height: 300px"
              >
                <span class="grey--text"> 목록이 존재하지 않습니다. </span>
              </div>
            </template>
          </div>
        </article>
      </template>

      <template v-if="listType === 'item'">
        <article class="flexTable">
          <div
            class="tableHaeder"
            style="width: 100%; background-color: rgb(245, 245, 245) !important"
          >
            <div class="settingHeader" style="width: 100%">
              <div
                class="text-center settingCell"
                v-for="col in excelItemHeaders"
                :key="col.value"
                :style="{ width: col.width }"
              >
                <span>{{ col.text }}</span>
              </div>
            </div>
          </div>
          <div class="settingTableBody">
            <template v-if="itemExcelFormList.length">
              <div
                v-for="(item, index) in itemExcelFormList"
                :key="`${item._id}_${index}`"
                class="settingRowCell text-center"
                style="width: 100%"
              >
                <div
                  class="settingCell"
                  v-for="(col, i) in excelItemHeaders"
                  :key="i"
                  :style="{ width: col.width }"
                >
                  <template v-if="col.value === 'inspect_status'">
                    <span>
                      {{ item.inspect_status ? "Y" : "N" }}
                    </span>
                  </template>
                  <template v-else-if="col.value === 'edit'">
                    <span>
                      <v-icon small @click="openEditClientForm(item)">
                        mdi-pencil
                      </v-icon>
                    </span>
                  </template>
                  <template v-else-if="col.value === 'production'">
                    <span>
                      {{ item.production ? item.production.name : "" }}
                    </span>
                  </template>

                  <template v-else>
                    <span>
                      {{ item[col.value] }}
                    </span>
                  </template>
                </div>
              </div>
            </template>
            <template v-else>
              <div
                class="
                  settingRowCell
                  text-center
                  d-flex
                  justify-center
                  align-center
                "
                style="width: 100%; height: 300px"
              >
                <span class="grey--text"> 목록이 존재하지 않습니다. </span>
              </div>
            </template>
          </div>
        </article>
      </template>

      <template v-if="listType === 'ctype'">
        <article class="flexTable">
          <div
            class="tableHaeder"
            style="width: 100%; background-color: rgb(245, 245, 245) !important"
          >
            <div class="settingHeader" style="width: 100%">
              <div
                class="text-center settingCell"
                v-for="col in excelCtypeHeaders"
                :key="col.value"
                :style="{ width: col.width }"
              >
                <span>{{ col.text }}</span>
              </div>
            </div>
          </div>
          <div class="settingTableBody">
            <template v-if="ctypeExcelFormList.length">
              <div
                v-for="(item, index) in ctypeExcelFormList"
                :key="`${item._id}_${index}`"
                class="settingRowCell text-center"
                style="width: 100%"
              >
                <div
                  class="settingCell"
                  v-for="(col, i) in excelCtypeHeaders"
                  :key="i"
                  :style="{ width: col.width }"
                >
                  <span>
                    {{ item[col.value] }}
                  </span>
                </div>
              </div>
            </template>
            <template v-else>
              <div
                class="
                  settingRowCell
                  text-center
                  d-flex
                  justify-center
                  align-center
                "
                style="width: 100%; height: 300px"
              >
                <span class="grey--text"> 목록이 존재하지 않습니다. </span>
              </div>
            </template>
          </div>
        </article>
      </template>
    </template>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import OrderStatus from "@/assets/data/status/OrderStatus";

export default {
  computed: {
    ...mapGetters({
      clientList: "getClientList",
      excelFormList: "getExcelFormList",
      itemExcelFormList: "getItemExcelFormList",
      ctypeExcelFormList: "getCtypeExcelFormList",
      itemList: "getItemList",
      itemClientList: "getItemClientList",
      ctypeList: "getCtypeList",
    }),

    filterClientList() {
      const clientSearchRegex = this.clientSearch
        ? new RegExp(this.clientSearch, "i")
        : null;
      const billClientSearchRegex = this.billClientSearch
        ? new RegExp(this.billClientSearch, "i")
        : null;
      let clientList = [...this.clientList].sort((a, b) => a.status - b.status);

      // 필터 조건을 하나의 함수로 결합
      return clientList.filter((data) => {
        const matchesClientSearch = this.clientSearch
          ? data.name.match(clientSearchRegex)
          : true;

        // 2. billClientSearch 조건 (생산처 검색)
        const matchesbillClientSearch = this.billClientSearch
          ? data.bill_client &&
            data.bill_client.name.match(billClientSearchRegex)
          : true;

        // 3. itemStatus 조건 (상태 필터)
        let matchesClientStatus = true; // 기본적으로 모든 상태를 허용
        if (this.clientStatus !== "all") {
          matchesClientStatus =
            this.clientStatus === "confirm"
              ? data.status === "40" // 상태가 "confirm"일 경우
              : this.clientStatus === "delete"
              ? data.status === "99" // 상태가 "delete"일 경우
              : false; // 상태가 "confirm"도 아니고 "delete"도 아니면 false
        }
        let matchClientType = true; // 구분이 전체인경우
        if (this.clientFilter !== "all") {
          matchClientType = data.company_type === this.clientFilter;
        }
        // 모든 조건이 true인 항목만 필터링
        return (
          matchesbillClientSearch &&
          matchesClientSearch &&
          matchesClientStatus &&
          matchClientType
        );
      });
    },

    filterItemList() {
      const itemSearchRegex = this.itemSearch
        ? new RegExp(this.itemSearch, "i")
        : null;
      const productionSearchRegex = this.productionSearch
        ? new RegExp(this.productionSearch, "i")
        : null;
      let itemList = [...this.itemList].sort((a, b) => a.status - b.status);

      // 필터 조건을 하나의 함수로 결합
      return itemList.filter((x) => {
        const matchesItemSearch = this.itemSearch
          ? x.name.match(itemSearchRegex)
          : true;

        // 2. productionSearch 조건 (생산처 검색)
        const matchesProductionSearch = this.productionSearch
          ? x.production.name.match(productionSearchRegex)
          : true;

        // 3. itemStatus 조건 (상태 필터)
        let matchesItemStatus = true; // 기본적으로 모든 상태를 허용
        if (this.itemStatus !== "all") {
          matchesItemStatus =
            this.itemStatus === "confirm"
              ? x.status === "40" // 상태가 "confirm"일 경우
              : this.itemStatus === "delete"
              ? x.status === "99" // 상태가 "delete"일 경우
              : false; // 상태가 "confirm"도 아니고 "delete"도 아니면 false
        }

        // 모든 조건이 true인 항목만 필터링
        return (
          matchesItemSearch && matchesProductionSearch && matchesItemStatus
        );
      });
    },
    filterCtypeList() {
      let ctypeList = [...this.ctypeList].sort((a, b) => a.status - b.status);

      const CtypeSearchRegex = this.ctypeSearch
        ? new RegExp(this.ctypeSearch, "i")
        : true;

      // 필터 조건을 하나의 함수로 결합
      return ctypeList.filter((x) => {
        const matchesCtypeSearch = this.ctypeSearch
          ? x.name.match(CtypeSearchRegex)
          : true;

        // 3. itemStatus 조건 (상태 필터)
        let matchesCtypeStatus = true; // 기본적으로 모든 상태를 허용
        if (this.ctypeStatus !== "all") {
          matchesCtypeStatus =
            this.ctypeStatus === "confirm"
              ? x.status === "40" // 상태가 "confirm"일 경우
              : this.ctypeStatus === "delete"
              ? x.status === "99" // 상태가 "delete"일 경우
              : false; // 상태가 "confirm"도 아니고 "delete"도 아니면 false
        }

        // 모든 조건이 true인 항목만 필터링
        return matchesCtypeSearch && matchesCtypeStatus;
      });
    },
    // selectedLength() {
    //   return this.selectedLengthValue;
    // },
    // selectedLength() {
    //   const list =
    //     this.listType === "client"
    //       ? this.filterClientList
    //       : this.listType === "item"
    //       ? this.filterItemList
    //       : this.filterCtypeList;

    //   return list.filter((item) => item.checked).length;
    // },
    listLength() {
      const listMap = {
        client: this.filterClientList,
        item: this.filterItemList,
        ctype: this.filterCtypeList,
      };
      return listMap[this.listType].length;
    },
    uploadLength() {
      const list =
        this.listType === "client"
          ? this.excelFormList
          : this.listType === "item"
          ? this.itemExcelFormList
          : this.ctypeExcelFormList;
      return list.length;
    },
  },
  data() {
    return {
      editItem: {},
      viewType: "list",
      listType: "client",
      listTypes: [
        {
          value: "client",
          label: "거래처",
        },
        {
          value: "item",
          label: "아이템",
        },
        {
          value: "ctype",
          label: "복종",
        },
      ],
      clientStatus: "confirm",
      statusType: [
        {
          value: "all",
          label: "전체",
        },
        {
          value: "confirm",
          label: "승인 확인",
        },
        {
          value: "delete",
          label: "삭제",
        },
      ],
      clientFilter: "all",
      clientFilterItems: this.$store.state.client_kind,
      clientSearch: "",
      billClientSearch: "",
      clientHeaders: [
        {
          text: "",
          value: "check",
          width: "3%",
          align: "center",
        },
        {
          text: "구분",
          value: "company_type",
          width: "10%",
          align: "center",
        },
        {
          text: "계산서 발행처",
          value: "bill_client",
          width: "20%",
          align: "center",
        },

        { text: "회사명", value: "name", align: "center", width: "20%" },
        {
          text: "사업자번호",
          value: "business_num",
          align: "center",
          width: "10%",
        },
        { text: "대표명", value: "officer", align: "center", width: "10%" },
        { text: "전화번호", value: "tel", align: "center", width: "5%" },
        { text: "이메일", value: "email", align: "center", width: "10%" },
        { text: "상태", value: "status", align: "center", width: "5%" },
        { value: "edit", width: "10%", sortable: false },
      ],
      excelClientHeaders: [
        { text: "구분", value: "company_type", width: "3%" }, // 거래처 종류
        { text: "회사명구분", value: "type_postion", width: "3%" }, // 거래처 종류
        { text: "지점", value: "zone", width: "5%" }, // 거래처 종류
        { text: "회사명", value: "name_only", width: "10%" }, // 회사명
        { text: "계산서 발행처", value: "bill_client", width: "10%" }, // 거래처 종류
        {
          text: "사업자번호",
          value: "business_num",
          width: "10%",
        }, // 사업자 번호
        { text: "업종", value: "industry", width: "10%" }, // 업종
        { text: "대표", value: "officer", width: "10%" }, // 대표
        { text: "담당자", value: "liaison", width: "10%" }, // 연락 담당자
        { text: "전화번호", value: "tel", width: "7%" }, // 전화번호
        { text: "팩스번호", value: "fax", width: "7%" }, // 팩스번호
        { text: "이메일", value: "email", width: "7%" }, // 이메일
        { text: "주소", value: "address", width: "15%" }, // 주소
        { text: "", value: "modify", width: "5%" }, // 주소
      ],
      // viewTel: "",
      // viewBusinessNum: "",
      viewClientTel: "",
      viewClientBusinessNum: "",
      dialog_client: false,
      // setting - item
      // itemList: [],
      inspectStatusText: "검사포함",
      itemSearch: "",
      productionSearch: "",
      itemStatus: "confirm",
      itemHeaders: [
        { text: "", value: "check", align: "center", width: "3%" },
        { text: "아이템명", value: "name", align: "center", width: "20%" },
        { text: "가격", value: "unit_price", align: "center", width: "10%" },
        { text: "생산처", value: "production", align: "center", width: "20%" },
        {
          text: "생산처 Item No",
          value: "production_item",
          align: "center",
          width: "20%",
        },
        { text: "담당자", value: "liaison", align: "center", width: "10%" },
        { text: "상태", value: "status", align: "center", width: "10%" },
        { value: "edit", sortable: false, width: "10%" },
      ],
      excelItemHeaders: [
        { text: "아이템명", value: "name", width: "5%" },
        { text: "가격", value: "unit_price", width: "5%" },
        { text: "가격 단위", value: "currency_sign", width: "5%" },
        { text: "길이 단위", value: "unit_length", width: "5%" },
        { text: "검사 포함 여부", value: "inspect_status", width: "5%" },
        { text: "생산처 ", value: "production", width: "5%" },
        { text: "생산처 Item ", value: "production_item", width: "5%" },
        { text: "경사", value: "slope", width: "5%" },
        { text: "위사", value: "oblique", width: "5%" },
        { text: "사종", value: "variety", width: "5%" },
        { text: "폭", value: "width", width: "5%" },
        { text: "전체 폭", value: "full_width", width: "5%" },
        { text: "무게단위", value: "unit_width", width: "5%" },
        { text: "밀도", value: "density", width: "5%" },
        { text: "중량", value: "weight", width: "5%" },
        { text: "무게", value: "unit_weight", width: "5%" },
        { text: "후가공", value: "post_processing", width: "5%" },
        { text: "내용", value: "content", width: "5%" },
        { text: "담당자", value: "liaison", width: "5%" },
      ],
      dialog_item: false,
      // setting - ctype
      // ctypeList: [],
      ctypeSearch: "",
      ctypeStatus: "confirm",
      ctypeHeaders: [
        { text: "", value: "check", align: "center", width: "3%" },
        { text: "복종명", value: "name", align: "center", width: "77%" },
        { text: "상태", value: "status", align: "center", width: "10%" },
        { value: "edit", width: "10%", sortable: false },
      ],
      excelCtypeHeaders: [{ text: "이름", value: "name", width: "100%" }],
      dialog_ctype: false,
      allChecked: { client: false, item: false, ctype: false },
      OrderStatus,
      selectedLength: 0,
    };
  },
  methods: {
    viewChange() {
      this.viewType = this.viewType === "list" ? "upload" : "list";
    },
    openFormParse() {
      this.editItem = {};
      const type =
        this.listType === "client"
          ? "Client"
          : this.listType === "item"
          ? "Item"
          : "Ctype";

      if (type === "Client") this.editItem.kind = 1;
      if (type === "Item")
        this.editItem = {
          currency_sign: "₩",
          unit_length: "y",
          unit_width: "in",
          unit_weight: "g/y",
          inspect_status: true,
        };
      this.editItem.type = type;

      this.$store.commit("setEditedItemForm", this.editItem);
    },
    newAddItem() {
      this.$store.commit("setEditedItemForm");
      this.openFormParse();

      this.$store.commit("setDailogStatus", {
        status: true,
        kind: "setting_form",
      });
    },
    downloadExcel() {
      if (this.listType === "client")
        this.$store.dispatch("CLIENT_DOWNLOAD_EXCEL");
      if (this.listType === "item") this.$store.dispatch("ITEM_DOWNLOAD_EXCEL");
      if (this.listType === "ctype")
        this.$store.dispatch("CTYPE_DOWNLOAD_EXCEL");
    },
    listChange(type) {
      this.listType = type;

      // if (target && this.viewType !== target) this.viewType = target;
    },
    selectedLengthUpdate() {
      const list =
        this.listType === "client"
          ? this.filterClientList
          : this.listType === "item"
          ? this.filterItemList
          : this.filterCtypeList;

      this.selectedLength = list.reduce(
        (acc, item) => (item.checked === true ? acc + 1 : acc),
        0,
      );
    },
    async uploadSave(kind) {
      const formLength =
        kind === "client"
          ? this.excelFormList.length
          : kind === "item"
          ? this.itemExcelFormList.length
          : this.ctypeExcelFormList.length;
      if (!formLength) {
        this.$store.commit("setSnackBar", `저장할 항목이 없습니다.`);

        return;
      }
      if (confirm(`총 ${formLength}건을 저장 하시겠습니까?`)) {
        await this.$store.dispatch("EXCEL_UPLOAD_SAVE", kind);
      }
    },
    async removeCol() {
      const type = this.listType;
      const list =
        this.listType === "client"
          ? this.filterClientList
          : this.listType === "item"
          ? this.filterItemList
          : this.filterCtypeList;
      const selected = [];

      list.forEach((x) => {
        if (x.checked) selected.push(x);
      });

      if (!selected.length) {
        this.$store.commit("setSnackBar", "삭제할 항목을 선택하세요.");
        return;
      }
      if (confirm(`${selected.length}건을 삭제하시겠습니까?`)) {
        this.$store.dispatch("SET_API_LOADING", true);
        for (let i = 0; i < selected.length; i++) {
          selected[i].status = "99";
          this.$store.commit("setEditedItemForm", selected[i]);
          type === "client"
            ? await this.$store.dispatch("SAVE_CLIENT")
            : type === "item"
            ? this.$store.dispatch("SAVE_ITEM")
            : this.$store.dispatch("SAVE_CTYPE");
          selected[i].checked = false;
        }
        await this.$store.dispatch("SET_CLIENTLIST");
        await this.$store.dispatch("SET_USE_CLIENTLIST");

        this.$store.commit(
          "setSnackBar",
          `${selected.length}건이 삭제되었습니다.`,
        );
        this.selectedLengthUpdate();
        this.$store.dispatch("SET_API_LOADING", false);
        this.allChecked[type] = false;
      }
    },
    allCheckItem(list, type) {
      this.allChecked[type] = !this.allChecked[type];
      list.forEach((x) => {
        if (x.status !== "99") x.checked = this.allChecked[type];
      });

      this.selectedLengthUpdate();
    },
    // setting - client
    checkItem(item, list, type) {
      item.checked = !item.checked;

      if (item.checked) {
        let count = 0;
        list.forEach((x) => {
          if (x.checked) count++;
        });
        if (count === list.length) this.allChecked[type] = true;
      } else {
        this.allChecked[type] = false;
      }
      this.selectedLengthUpdate();
    },
    openNewClient() {
      this.$store.commit("setEditedItemForm");
      this.$store.state.editedItem.type = "Client";
      this.$store.state.editedItem.kind = 1;
      this.$store.commit("setDailogStatus", {
        status: true,
        kind: "setting_form",
      });
    },
    openEditClientForm(item) {
      this.$store.commit("setEditedItemForm");
      this.editedItem = { ...item };
      this.editedItem.type = "Client";

      this.editedItem.company = this.$store.state.auth_company;
      this.editedItem.business_num
        ? (this.editedItem.business_num = this.getBusinessNumMask(
            this.editedItem.business_num,
          ))
        : "";

      this.editedItem.tel
        ? (this.editedItem.tel = this.getPhoneMask(this.editedItem.tel))
        : "";
      this.$store.commit("setEditedItemForm", this.editedItem);
      this.$store.commit("setDailogStatus", {
        status: true,
        kind: "setting_form",
      });
    },
    closeEditClientForm() {
      this.dialog_client = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    async saveClientForm() {
      if (
        this.clientList.find((x) => !x._id && x.name === this.editedItem.name)
      ) {
        this.$store.commit("setSnackBar", "동일이름의 거래처가 존재합니다.");
        return;
      }

      if (!this.editedItem.kind) {
        this.$store.commit("setSnackBar", "거래처 종류를 선택해주세요.");

        return;
      }
      if (!this.editedItem.name) {
        this.$store.commit("setSnackBar", `회사명을 입력해주세요.`);
        return;
      }
      this.editedItem.status = "40";

      this.editedItem.business_num = this.viewClientBusinessNum.replace(
        /[^0-9]/g,
        "",
      );

      await this.postClient();
      // await this.getClientList();
      await this.$store.dispatch("SET_CLIENTLIST");

      this.$store.commit(
        "setSnackBar",
        `${this.editedItem.name} 저장이 완료되었습니다.`,
      );

      this.closeEditClientForm();
    },
    // setting - item
    openNewItemForm() {
      this.editedItem = {
        currency_sign: "₩",
        unit_length: "y",
        unit_width: "in",
        unit_weight: "g/y",
        inspect_status: true,
      };
      this.editedItem.type = "Item";
      this.$store.commit("setEditedItemForm", this.editedItem);
      this.$store.commit("setDailogStatus", {
        status: true,
        kind: "setting_form",
      });
    },
    StatusCheck() {
      this.editedItem.inspect_status
        ? (this.inspectStatusText = "검사포함")
        : (this.inspectStatusText = "검사미포함");
    },
    openEditItemForm(item) {
      this.$store.commit("setEditedItemForm");
      this.editedItem = { ...item };
      this.editedItem.type = "Item";
      this.editedItem.company = this.$store.state.auth_company;

      this.$store.commit("setEditedItemForm", this.editedItem);
      this.$store.commit("setDailogStatus", {
        status: true,
        kind: "setting_form",
      });
    },
    closeEditItemForm() {
      this.dialog_item = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    async saveItemForm() {
      if (!this.editedItem.name) {
        this.$store.commit("setSnackBar", `아이템명을 입력해주세요.`);

        return;
      }
      if (!this.editedItem.production) {
        this.$store.commit("setSnackBar", `생산처를 선택해주세요.`);
        return;
      }
      if (!this.editedItem.production_item) {
        this.$store.commit("setSnackBar", `아이템명(생산처)를 입력해주세요.`);
        return;
      }
      this.editedItem.status = "40";

      await this.postItem();
      await this.$store.dispatch("SET_ITEMLIST");
      this.$store.commit(
        "setSnackBar",
        `${this.editedItem.name} 저장이 완료되었습니다.`,
      );
      this.closeEditItemForm();
    },
    excelUpload(type) {
      this.$store.dispatch("ExcelUpload", { type: type, file: this.file });
    },
    excelUploadDialogOpen() {
      this.$store.commit("setDailogStatus", {
        status: true,
        kind: "dialog_ExcelUpload",
      });
      this.$store.state.excelUploadType = this.listType;
    },
    itemWidthCheck() {
      if (
        this.editedItem.width !== "" &&
        this.editedItem.full_width !== "" &&
        +this.editedItem.width > +this.editedItem.full_width
      ) {
        this.$store.commit(
          "setSnackBar",
          `가용 폭은 전체 폭을 초과할 수 없습니다.`,
        );
        this.editedItem.width = "";
      }
    },

    // setting - ctype
    openNewCtypeForm() {
      this.editedItem = Object.assign({});
      this.editedItem.type = "Ctype";
      this.$store.commit("setEditedItemForm", this.editedItem);
      this.$store.commit("setDailogStatus", {
        status: true,
        kind: "setting_form",
      });
    },
    openEditCtypeForm(item) {
      this.editedItem = { ...item };
      this.editedItem.type = "Ctype";
      this.editedItem.company = this.$store.state.auth_company;
      this.$store.commit("setEditedItemForm", this.editedItem);
      this.$store.commit("setDailogStatus", {
        status: true,
        kind: "setting_form",
      });
    },
    closeEditCtypeForm() {
      this.dialog_ctype = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    async saveCtypeForm() {
      if (!this.editedItem.name) {
        this.$store.commit("setSnackBar", `복종명을 입력해주세요.`);
        return;
      }
      await this.postCtype();
      await this.$store.dispatch("SET_CTYPELIST");
      this.$store.commit(
        "setSnackBar",
        `${this.editedItem.name} 저장이 완료되었습니다.`,
      );
      this.closeEditCtypeForm();
    },
  },
};
</script>

<style></style>
