<template>
  <v-dialog
    max-width="600px"
    :value="isCreateUserDialog"
    @click:outside="$store.state.isDialog = false"
    @keydown.esc="$store.state.isDialog = false"
  >
    <v-card>
      <v-card-title>
        <div class="d-flex align-center" style="width: 100%">
          <span>신규직원 등록</span>
          <div class="ml-auto pr-2">
            <v-btn
              icon
              color="red"
              large
              @click="$store.state.isDialog = false"
            >
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </div>
        </div>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-container>
          <v-form
            v-model="valid"
            ref="accountForm"
            @submit.prevent="createUser"
          >
            <div>
              <v-text-field
                v-model="name"
                label="이름"
                type="text"
                prepend-icon="mdi-account"
                :rules="[() => !!name || '이름은 필수 값입니다.']"
                :hint="name_hint"
                :persistent-hint="true"
                class="pt-4"
                required
              />

              <v-text-field
                v-model="uuid"
                label="사용자 아이디"
                prepend-icon="mdi-email"
                type="text"
                :rules="[() => !!uuid || '사용자 아이디는 필수 값입니다.']"
                @input="uuid_hint = '사용자 아이디를 입력하세요. (이메일 형식)'"
                :hint="uuid_hint"
                :error-messages="idErrorMessege"
                :persistent-hint="true"
                style="width: 100%"
                class="pt-4"
                ref="uuid"
                required
              >
              </v-text-field>
              <v-text-field
                v-model="password"
                :append-icon="isPw ? 'mdi-eye' : 'mdi-eye-off'"
                label="사용자 비밀번호"
                :rules="[
                  () => !!password || '사용자 비밀번호는 필수 값입니다.',
                ]"
                prepend-icon="mdi-lock"
                :type="isPw ? 'text' : 'password'"
                :hint="passwordHint"
                :persistent-hint="true"
                @click:append="isPw = !isPw"
                style="width: 100%"
                class="pt-4"
                required
              />
              <v-text-field
                v-model="phone"
                label="전화번호"
                prepend-icon="mdi-phone"
                type="text"
                :hint="phone_hint"
                @input="phone = getPhoneMask($event)"
                :persistent-hint="true"
                style="width: 100%"
                class="pt-4"
                counter="13"
                maxlength="13"
              />

              <v-select
                v-model="grade"
                :items="$store.getters.userGradeWithoutOwner"
                item-text="label"
                hide-details
                prepend-icon="mdi-account-cog"
                dense
                outlined
                item-color="blue"
                label="등급"
                class="pt-4"
              ></v-select>
            </div>
            <div class="pt-6 d-flex justify-center">
              <v-btn width="200" color="primary" dark type="submit">
                등록
              </v-btn>
            </div>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { validatePassword } from "@/utils/validation";
import { idCheck, register } from "@/api/auth";
export default {
  data() {
    return {
      name: "",
      uuid: "",
      password: "",
      phone: "",
      name_hint: "사용자 이름을 입력하세요.",
      phone_hint: "휴대폰 번호를 입력해주세요.",
      uuid_hint: "사용자 아이디를 입력하세요. (이메일 형식)",
      isPw: false,
      grade: "0",
      formHasErrors: false,
      errorMessages: "",
      valid: true,
      idErrorMessege: "",
    };
  },
  components: {},
  computed: {
    passwordHint() {
      let hint = "";
      if (!this.password) hint = "사용자 비밀번호를 입력하세요.";
      if (this.password && validatePassword(this.password))
        hint = "비밀번호는 8~20자 이상 영문,숫자,특수문자로 입력해주세요";

      return hint;
    },
    isCreateUserDialog() {
      return this.$store.state.isDialog === true &&
        this.$store.state.isKind === "new_user_dialog"
        ? true
        : false;
    },
  },
  methods: {
    async createUser() {
      if (!this.$refs.accountForm.validate()) return;
      this.formHasErrors = false;
      const form = this.parseForm();

      if (!(await this.uuidOverlapCheck())) {
        this.idErrorMessege = "중복된 아이디입니다. 변경해주세요";
        return this.$store.commit(
          "setSnackBar",
          `존재하는 아이디입니다. 아이디를 변경해주세요.`,
        );
      }

      try {
        const newUser = await register(form);
        if (newUser.status === 200) {
          this.$store.commit("setSnackBar", `신규 직원이 등록 되었습니다.`);
          await this.$store.dispatch("SET_USERLIST");
          this.initFormData();
        }
      } catch (e) {
        this.$store.commit(
          "setSnackBar",
          `저장에 실패하였습니다. 다시 시도해주세요.`,
        );
      }
    },
    initFormData() {
      this.uuid = "";
      this.password = "";
      this.phone = "";
      this.grade = "0";
      this.name = "";
    },
    parseForm() {
      const form = {
        company: this.$store.state.auth_company,
        uuid: this.uuid,
        password: this.password,
        phone: this.phone,
        grade: this.grade,
        name: this.name,
        signStatus: "40",
        isActive: true,
      };

      return form;
    },

    async uuidOverlapCheck() {
      let isOverlap = false;

      try {
        const result = await idCheck(this.uuid);

        if (result.status !== 200) throw `error: ${result.status}`;

        isOverlap = result.data;
      } catch (e) {
        console.log(e);
      }

      return isOverlap;
    },
  },
};
</script>
