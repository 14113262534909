<template>
  <v-container fluid class="listContainer">
    <div class="inputTitle">오더 - 입력설정</div>
    <div class="settingGreyCard">
      <div class="headerRight pb-2">
        <v-btn
          color="#2c98f0"
          width="80"
          height="30"
          class="headerRight"
          @click="saveCompanyForm"
        >
          <span class="white--text">저장</span>
        </v-btn>
      </div>
      <div class="inputBodyGrid">
        <div class="labelConfirmButton">
          <div>사용</div>
          <div>
            <input
              class="v-checkbox"
              type="checkbox"
              style="transform: scale(1.1)"
              v-model="company.input_order_status"
            />
          </div>
        </div>
        <div class="mt-2">
          <v-card outlined variant="tonal" color="grey lighten-5 ">
            <v-card-title> 오더번호 직접입력 설정 </v-card-title>

            <v-card-text>
              <span class="grey--text">
                이 기능을 사용 시 오더번호가 자동입력 된 번호가 아닌 직접 입력된
                번호로 나타납니다.
              </span>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </div>
    <div class="inputTitle">생산 - 가공의뢰서 입력설정</div>
    <div class="settingGreyCard">
      <div class="headerRight">
        <v-btn
          color="#2c98f0"
          width="80"
          height="30"
          class="headerRight"
          @click="saveCompanyForm"
        >
          <span class="white--text">저장</span>
        </v-btn>
      </div>
      <div class="inputBodyGrid">
        <div class="labelConfirmButton">
          <div>사용</div>
          <div>
            <input
              class="v-checkbox"
              type="checkbox"
              v-model="company.manu_confirm_status"
              style="transform: scale(1.1)"
            />
          </div>
        </div>

        <div>
          <v-textarea
            rows="5"
            label="확인사항"
            hide-details
            class="lefted-input"
            clearable
            no-resize
            background-color="grey lighten-5"
            v-model="company.manu_confirm"
          />
        </div>

        <div class="labelConfirmButton">
          <div>사용</div>
          <div>
            <input
              class="v-checkbox"
              type="checkbox"
              style="transform: scale(1.1)"
              v-model="company.manu_pre_status"
            />
          </div>
        </div>
        <div>
          <v-textarea
            rows="5"
            label="주의사항"
            hide-details
            class="lefted-input"
            clearable
            no-resize
            background-color="grey lighten-5"
            v-model="company.manu_pre"
          />
        </div>
      </div>
    </div>
    <div class="inputTitle">생산 - 출고의뢰서 입력설정</div>

    <div class="settingGreyCard">
      <div class="headerRight">
        <v-btn
          color="#2c98f0"
          width="80"
          height="30"
          class="headerRight"
          @click="saveCompanyForm"
        >
          <span class="white--text">저장</span>
        </v-btn>
      </div>
      <div class="inputBodyGrid">
        <div class="labelConfirmButton">
          <div>사용</div>
          <div>
            <input
              class="v-checkbox"
              type="checkbox"
              style="transform: scale(1.1)"
              v-model="company.release_pre_status"
            />
          </div>
        </div>
        <div>
          <v-textarea
            rows="5"
            label="주의사항"
            hide-details
            class="lefted-input"
            clearable
            no-resize
            background-color="grey lighten-5"
            v-model="company.release_pre"
          />
        </div>
      </div>
    </div>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import { putCompany, postCompany } from "@/api/company";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      company: "getEditCompany",
    }),
  },
  methods: {
    async saveCompanyForm() {
      if (confirm("입력 설정을 저장하시겠습니까?")) {
        this.company.id = this.$store.state.auth_company;
        await this.postCompany();
        this.$store.commit("setSnackBar", `입력 설정이 저장 되었습니다.`);
        await this.$store.dispatch("GET_COMPANY");
      }
    },
    async postCompany() {
      try {
        const result = this.company._id
          ? await putCompany(this.company)
          : await postCompany(this.company);
        if (result.status !== 200) throw `error : ${result.status}`;
      } catch (e) {
        alert(e);
      }
    },
  },
};
</script>
