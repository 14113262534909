<template>
  <div>
    <v-tabs v-model="tabs" bg-color="primary">
      <v-tab value="service">사용중인 서비스</v-tab>
      <v-tab value="payment">결제</v-tab>
      <v-tab value="paymentList">결제 내역</v-tab>
    </v-tabs>
    <v-divider />

    <div class="serviceArea">
      <template v-if="tabs === 0">
        <div class="inputTitle">현재 사용중인 서비스</div>

        <v-card class="inputCard">
          <template v-if="!$store.state.company.is_membership">
            <div class="mt-2 serviceBody">
              <div class="d-flex">
                <span class="ServicebodyTitle pr-2">
                  {{
                    $store.state.company.is_trial
                      ? "무료 체험중"
                      : "무료 체험 기간 만료"
                  }}
                </span>
                <div
                  class="titleCard"
                  :class="!$store.state.company.is_trial ? 'red--text' : ''"
                >
                  <template v-if="$store.state.company.is_trial">
                    구독중
                  </template>
                  <template v-else> 만료 </template>
                </div>
                <v-btn
                  color="#2c98f0"
                  width="120"
                  height="30"
                  class="headerRight"
                  @click="tabs = 1"
                >
                  <span class="white--text">결제하기</span>
                </v-btn>
              </div>
              <span>
                {{
                  $store.state.company.is_trial
                    ? "무료 체험중"
                    : "무료 체험 기간 만료"
                }}
                3개월간 원단마니아 서비스를 무료로 이용할 수 있습니다.</span
              >
            </div>
          </template>

          <template v-if="$store.state.company.is_membership">
            <div class="serviceBody">
              <div class="membershipInfoGrid">
                <div>
                  <span class="bankingcardBodyText"> 서비스명 : </span>
                </div>
                <div>
                  <span class="font-weight-black">
                    {{ membershipInfo.serviceName }}
                  </span>
                </div>

                <div>
                  <span class="bankingcardBodyText"> 사용 기간 : </span>
                </div>
                <div>
                  <span class="">{{ membershipInfo.useDate }} </span>
                </div>
                <div>
                  <span class="bankingcardBodyText"> 남은 기간 : </span>
                </div>
                <div>
                  <span class="font-weight-black">
                    {{
                      membershipInfo.remaining_period
                        ? `${membershipInfo.remaining_period} 일`
                        : ""
                    }}
                  </span>
                </div>
                <div>
                  <span class="bankingcardBodyText"> 다음 결제 예정일 : </span>
                </div>
                <div>
                  <span class=""> {{ membershipInfo.next_order_date }} </span>
                </div>
                <div>
                  <span class="bankingcardBodyText"> 결제 예정 금액 : </span>
                </div>
                <div>
                  <span class="">
                    {{
                      membershipInfo.expected_price
                        ? `₩${membershipInfo.expected_price}`
                        : "" | makeComma
                    }}
                  </span>
                </div>
              </div>
            </div>
          </template>
        </v-card>
        <v-card class="infoCard">
          <template v-if="!$store.state.company.is_membership">
            <div class="mt-2 serviceBody">
              <div class="d-flex">
                <v-icon color="#4fbec5">mdi-bullhorn-variant-outline</v-icon>
                <span class="pl-4">
                  무료체험 기간이
                  <span class="font-weight-bold">
                    {{ dateFormat($store.state.company.trial_end_date) }}</span
                  >일에 종료됩니다. <br />
                  무료체험 기간이 종료되면 저장을 비롯한 여러기능이 사용중지
                  됩니다.<br />
                </span>
              </div>
            </div>
          </template>

          <template v-if="$store.state.company.is_membership">
            <div class="mt-2 serviceBody">
              <div class="d-flex">
                <v-icon color="#4fbec5">mdi-bullhorn-variant-outline</v-icon>
                <span class="pl-4">
                  * 갱신 정책 안내<br />
                  구독은 매년 자동 갱신됩니다. 갱신을 원하지 않으시면 만료일 7일
                  전까지 해지해 주세요.
                  <p />
                  * 문의 안내<br />구독 관련 문의 사항이 있으실 경우 고객센터 및
                  <span class="font-weight-bold">kjkim@softmani.com </span>으로
                  이메일 부탁드립니다.
                </span>
              </div>
            </div>
          </template>
        </v-card>
      </template>
      <template v-if="tabs === 1">
        <payment />
      </template>
      <template v-if="tabs === 2">
        <paymentList />
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import payment from "./payment.vue";
import paymentList from "./paymentList.vue";
export default {
  components: {
    payment,
    paymentList,
  },
  computed: {
    ...mapGetters({
      membershipInfo: "getMembershipInfo",
    }),
  },
  data() {
    return {
      tabs: "service",
    };
  },
  created() {
    this.$store.dispatch("CALL_MEMBERSHIP_INFO");
  },
};
</script>

<style></style>
