<template>
  <v-dialog
    :value="dialog_ExcelUpload"
    @click:outside="$store.state.isDialog = false"
    @keydown.esc="$store.state.isDialog = false"
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">파일 업로드</span>
      </v-card-title>
      <v-card-subtitle>
        <v-col>
          <span>
            1. 지정된 파일형식의 파일만 업로드 해주시기 바랍니다. (Xlsx)<br />
            2. 파일양식에 생성된 시트에 해당하는 데이터만 입력가능합니다.<br />
            3. 지원되는 엑셀 양식의 샘플 데이터 밑 부분부터 입력 해야합니다.
            (3번째 열)
          </span>
        </v-col>
        <v-col>
          <v-btn
            class="settingButton"
            color="green"
            @click="$store.dispatch('EXCEL_EXAMPLE_DOWNLOAD')"
          >
            <span class="white--text">엑셀 양식 다운로드</span>
          </v-btn>
        </v-col>
      </v-card-subtitle>
      <v-card-text>
        <v-container>
          <v-row>
            <v-file-input
              v-model="file"
              label="파일 선택"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              placeholder="Select File"
              prepend-icon="mdi-microsoft-excel"
              color="green"
              clearable
              dense
              outlined
            >
              <template v-slot:selection="{ text }">
                <span class="text-caption">
                  {{ text }}
                </span>
              </template>
            </v-file-input>
            <v-btn
              color="green"
              class="white--text ml-3 mt-0 settingButton"
              @click="excelUpload"
            >
              <span class="">파일 등록</span>
              <v-icon color="white">mdi-file-upload-outline</v-icon>
            </v-btn>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="$store.state.isDialog = false"
        >
          닫기
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      file: null,
    };
  },
  computed: {
    dialog_ExcelUpload() {
      return this.$store.state.isDialog === true &&
        this.$store.state.isKind === "dialog_ExcelUpload"
        ? true
        : false;
    },
  },
  methods: {
    excelUpload() {
      if (!this.file) {
        this.$store.commit("setSnackBar", "등록된 파일이 없습니다.");
        return;
      }
      this.$store.dispatch("EXCEL_UPLOAD", this.file);
      this.$store.commit("setSnackBar", `업로드가 완료되었습니다.`);
      this.$store.state.isDialog = false;
    },
  },
};
</script>
