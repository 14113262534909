<template>
  <div>
    <div class="d-flex submenuArea">
      <div class="d-flex align-center infoArea">
        총 &nbsp;<span class="blue--text">{{ pageList.length }}</span
        >건
      </div>
      <div class="selectArea">
        <v-select
          :items="sortList"
          label="정렬"
          style="width: 150px"
          v-model="sort"
          @change="changeFilter()"
        >
        </v-select>
        <v-select
          :items="statusList"
          v-model="status"
          label="상태"
          style="width: 150px"
          @change="changeFilter()"
        >
        </v-select>
      </div>
    </div>
    <div class="paymentListGrid">
      <div v-for="col in header" :key="col._id" class="paymentListGridHeader">
        <div>
          <div>
            <div>
              <span>{{ col.text }} </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <overlay-scrollbars
      :options="{
        overflowBehavior: {
          x: 'hidden',
        },
      }"
      ref="scrollBar"
      defer
    >
      <div
        style="
          max-height: calc(100vh - 224px);
          border-bottom: thin solid #0000001f;
        "
      >
        <template v-if="pageList.length">
          <div
            class="paymentListGrid paymentListGridBody"
            v-for="item in pageList"
            :key="item._id"
          >
            <div v-for="head in header" :key="head.value" class="bodyItem">
              <span v-if="head.value === 'status'">
                <div class="paystatus">
                  <span
                    class="requestText"
                    :class="
                      item[head.value] === 'request' ||
                      item[head.value] === 'cancelRequest'
                        ? 'orange--text'
                        : item[head.value] === 'confirm'
                        ? 'cyan--text text--lighten-2'
                        : item[head.value] === 'cancel'
                        ? 'red--text'
                        : ''
                    "
                  >
                    {{ parseStatus(item[head.value]) }}
                  </span>
                </div>
              </span>
              <span v-else-if="head.value === 'price'">
                {{
                  item[head.value] ? `₩${item[head.value]}` : "" | makeComma
                }}</span
              >
              <span
                v-else-if="head.value === 'id'"
                class="text-decoration-underline"
              >
                {{ item[head.value] ? item[head.value] : "" }}</span
              >
              <span
                v-else-if="head.value === 'button' && item.status === 'request'"
              >
                <v-btn color="red" small @click="refund(item)"
                  ><span class="white--text">취소 요청</span></v-btn
                >
              </span>
              <span v-else>
                {{ item[head.value] ? item[head.value] : "" }}</span
              >
            </div>
          </div>
        </template>
        <template v-else>
          <div
            class="paymentListGrid paymentListGridBody"
            v-for="(item, i) in 1"
            :key="i"
            style="width: 100%"
          >
            <div v-for="head in header" :key="head.value" class="bodyItem" />
          </div>
        </template>
        <div ref="endPoint" style="height: 1px" />
      </div>
    </overlay-scrollbars>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import paymentStatus from "@/assets/data/status/paymentStatus";
export default {
  computed: {
    ...mapGetters({
      pageList: "getPaymentList",
    }),
  },
  data() {
    return {
      header: [
        {
          text: "ID",
          width: "2%",
          align: "center",
          class: "tableHeaderBody",
          value: "id",
          filterable: false,
        },
        {
          text: "결제자",
          width: "2%",
          align: "center",
          class: "tableHeaderBody",
          value: "depositor",
        },
        {
          text: "결제일",
          width: "2%",
          align: "center",
          class: "tableHeaderBody",
          value: "pay_at",
        },
        {
          text: "결제항목",
          width: "2%",
          align: "center",
          class: "tableHeaderBody",
          value: "item",
        },
        {
          text: "사용기간",
          width: "5%",
          value: "useDate",
          align: "center",
          class: "tableHeaderBody",
        },
        {
          text: "결제금액",
          width: "5%",
          value: "price",
          align: "center",
          class: "tableHeaderBody",
        },
        {
          text: "상태",
          width: "5%",
          value: "status",
          align: "center",
          class: "tableHeaderBody",
        },
        {
          text: "",
          width: "5%",
          value: "button",
          align: "center",
          class: "tableHeaderBody",
        },
      ],
      statusList: [
        {
          text: "전체",
          value: "all",
        },
        {
          text: "결제 요청",
          value: "request",
        },
        {
          text: "결제 완료",
          value: "confirm",
        },
        {
          text: "결제 취소",
          value: "cancel",
        },
        {
          text: "취소 요청",
          value: "cancelRequest",
        },
      ],
      sortList: [
        {
          text: "오름차순 정렬",
          value: "asc",
        },
        {
          text: "내림차순 정렬",
          value: "desc",
        },
      ],
      status: "all",
      sort: "desc",
    };
  },
  methods: {
    parseStatus(item) {
      return paymentStatus[item];
    },
    changeFilter() {
      this.$store.dispatch("GET_PAYMENTLIST", {
        status: this.status,
        sort: this.sort,
      });
    },
    async refund(item) {
      if (confirm(`ID : ${item.id} 건을 환불 요청하시겠습니까?`)) {
        await this.$store.dispatch("REQUEST_REFUND", item);
        await this.$store.dispatch("GET_PAYMENTLIST", {
          status: this.status,
          sort: this.sort,
        });
      }
    },
  },
  created() {
    this.$store.dispatch("GET_PAYMENTLIST", {
      status: this.status,
      sort: this.sort,
    });
  },
};
</script>

<style></style>
