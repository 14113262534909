<template>
  <div class="settingAccount">
    <div class="settingGreyCard mt-4">
      <div class="d-flex">
        <div class="titleText">
          <span> 계정정보 변경</span>
        </div>
        <div class="userButtonArea">
          <v-btn color="#2c98f0" @click="modifyUser()" width="80" height="30">
            <span class="whiteText">저장</span>
          </v-btn>
        </div>
      </div>
      <v-text-field :value="$store.state.auth_uuid" label="아이디" disabled>
      </v-text-field>

      <v-text-field v-model="user.name" label="이름"></v-text-field>

      <v-text-field
        v-model="user.tel"
        @input="user.tel = getPhoneMask($event)"
        label="전화번호"
        counter="13"
        maxlength="13"
      ></v-text-field>
      <div class="pb-4">
        <v-btn color="red" width="80" height="30" to="/deleteAccount">
          <span class="whiteText">회원탈퇴</span>
        </v-btn>
      </div>
    </div>

    <div class="settingGreyCard">
      <div class="d-flex">
        <div class="titleText">
          <span> 비밀번호 변경</span>
        </div>
        <div class="userButtonArea">
          <v-btn
            color="#2c98f0"
            @click="modifyPassword()"
            :disabled="isModifyPwBtn()"
            width="80"
            height="30"
          >
            <span class="whiteText">저장</span>
          </v-btn>
        </div>
      </div>
      <v-text-field
        v-model="existingPw"
        label="현재 비밀번호"
        @keypress.enter="passwordCheck()"
        type="password"
        :hint="existingHint"
        :disabled="existingCheck"
        :persistent-hint="true"
      >
        <template v-slot:append="">
          <v-btn
            width="50"
            height="25"
            :disabled="existingCheck"
            @mouseup.stop
            @click.prevent.stop="passwordCheck()"
            color="rgba(0, 0, 0, 0.1)"
            class="confirmButton"
          >
            <span class="greyText">확인</span>
          </v-btn>
        </template>
      </v-text-field>

      <v-text-field
        v-model="modifyPw"
        label="변경 비밀번호"
        type="password"
        :hint="modifyPwHint()"
        :disabled="!existingCheck"
        :persistent-hint="true"
      ></v-text-field>

      <v-text-field
        v-model="checkPw"
        label="변경 비밀번호 확인"
        type="password"
        :hint="checkPwHint()"
        :disabled="!existingCheck"
        :persistent-hint="true"
      />
    </div>
  </div>
</template>

<script>
import { checkUserPw, modifyPassword, putUser } from "@/api/user";
import { validatePassword } from "@/utils/validation";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      user: "getUserInfo",
    }),
  },
  data() {
    return {
      existingPw: "", // 현재 비밀번호
      existingCheck: false, // 비밀번호 확인
      modifyPw: "", // 변경 비밀 번호
      checkPw: "", // 변경 비밀번호 확인
      existingHint: "현재 비밀번호를 입력 후 확인해주세요",
    };
  },
  methods: {
    checkPwHint() {
      let hint = "변경 될 비밀번호를 입력해주세요";
      if (this.checkPw && this.checkPw !== this.modifyPw)
        hint = "변경 비밀번호와 다릅니다. 다시 확인해주세요";
      if (this.checkPw && this.checkPw === this.modifyPw)
        hint = "변경될 비밀번호와 동일합니다.";
      return hint;
    },
    modifyPwHint() {
      let hint = "";

      if (this.modifyPw) hint = "변경 비밀번호 확인 해주세요";
      if (validatePassword(this.modifyPw))
        hint = "비밀번호는 8~20자 이상 영문,숫자,특수문자형태로 입력해주세요";
      if (this.modifyPw && this.modifyPw === this.existingPw)
        hint = "현재 비밀번호와 동일합니다. 새로운 비밀번호를 입력해주세요";
      return hint;
    },
    // 패스워드 변경 확인
    async passwordCheck() {
      if (!this.existingPw) return;
      try {
        const result = await checkUserPw({
          uuid: this.$store.state.auth_uuid,
          password: this.existingPw,
        });

        if (result.status !== 200) throw `error: ${result.status}`;

        this.existingCheck = result.data;
        if (!this.existingCheck)
          this.existingHint =
            "현재 비밀번호가 맞지 않습니다. 다시 입력해주세요";
        else this.existingHint = "변경할 비밀번호를 입력해주세요";
      } catch (e) {
        console.log(e);
      }
    },
    // 패스워드 변경
    async modifyPassword() {
      try {
        const result = await modifyPassword({
          uuid: this.$store.state.auth_uuid,
          password: this.modifyPw,
        });

        if (result.status !== 200) throw `error: ${result.status}`;

        if (result.data) {
          alert("비밀번호가 변경되었습니다. 다시 로그인해주세요");
          this.$store.dispatch("LOGOUT");
          this.$router.push("/login");
        } else {
          alert("비밀번호가 변경되지 않았습니다. 다시 시도 해주세요");
          this.initPassword();
        }
      } catch (e) {
        console.log(e);
      }
    },
    async modifyUser() {
      try {
        const result = await putUser({
          ...this.user,
        });

        if (result.status !== 200) throw `error: ${result.status}`;

        await this.$store.dispatch("GET_FIND_USER_INFO");
        this.$store.commit("setSnackBar", `계정 정보가 변경되었습니다.`);
      } catch (e) {
        console.log(e);
      }
    },
    initPassword() {
      this.existingPw = ""; // 현재 비밀번호
      this.existingCheck = false; // 비밀번호 확인
      this.modifyPw = ""; // 변경 비밀 번호
      this.checkPw = ""; // 변경 비밀번호 확인
      this.existingHint = "현재 비밀번호를 입력 후 확인해주세요";
    },
    isModifyPwBtn() {
      let isBtn = true;

      // 변경 비밀번호와 확인 비밀번호 같을 경우
      if (this.modifyPw && this.modifyPw === this.checkPw) isBtn = !isBtn;
      // 변경 비밀번호와 현재 비밀번호가 같을경우
      if (this.modifyPw === this.existingPw) isBtn = true;
      //특수, 숫자, 영뮨 포함 확인
      if (validatePassword(this.modifyPw)) isBtn = true;

      return isBtn;
    },
  },
};
</script>

<style></style>
